import React, { ElementType, Fragment, useRef } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'
import BackButtonArrowIcon from '../SvgIcons/BackButtonArrowIcon'
import { FontSize } from 'types'

interface IAppModalBoxProps {
  title?: any
  titleSize?: FontSize
  children?: any
  onClose?: (e: any) => void
  width?: string
  classes?: string
  titleFont?: string
  wrapperClass?: string
  backgroundColor: string
  childClass: string
  showCancelButton: boolean
  margin: string
  height: string
  isOnBoarding: boolean
  onBack?: () => {} | void
  Icon?: ElementType
  padding: string
  id?: string
  bgColor: string
  position: 'top' | 'center' | 'desktopTop'
  rounded?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  isBorderWidthFull?: boolean
  showBorder?: boolean // New prop to control the border visibility
}

export const AppModalBox = ({
  title,
  titleSize,
  children,
  onClose,
  width,
  classes,
  wrapperClass,
  backgroundColor,
  childClass,
  showCancelButton,
  margin,
  height,
  isOnBoarding,
  onBack,
  Icon,
  padding,
  bgColor,
  id = '',
  titleFont = '',
  position,
  rounded,
  isBorderWidthFull,
  showBorder = true, // Default to true
}: IAppModalBoxProps) => {
  const currentRef = useRef(null)
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        initialFocus={currentRef}
        id={id}
        as="div"
        className={`relative z-50 ${wrapperClass} `}
        onClose={() => undefined}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div ref={currentRef} className={`fixed inset-0 ${bgColor ? bgColor : 'bg-zinc'}`} />
        </Transition.Child>

        <div className={`fixed inset-0 overflow-y-auto ${margin}`}>
          <div className={`h-full flex items-center`}>
            <div
              className={`${
                rounded ? `rounded-${rounded}` : 'sm:rounded-xl xs:rounded-xl'
              }  modal-container ${height} xs:overflow-auto 
    ${
      width
        ? `${width} mx-auto`
        : `w-[800px] mx-auto ${
            position === 'desktopTop'
              ? 'sm:mt-12 sm:mb-auto'
              : position === 'center'
              ? 'my-auto'
              : position === 'top'
              ? 'mt-12 mb-auto'
              : ''
          }`
    }
    ${backgroundColor ? backgroundColor : 'bg-white'}
    shadow-xl
  `}
            >
              {(title || onClose) && (
                <div className={`flex flex-row justify-end items-center ${padding} top-0 `}>
                  {onBack && (
                    <span data-testid="back-button" onClick={onBack}>
                      <BackButtonArrowIcon className={'cursor-pointer mr-2'} />
                    </span>
                  )}
                  {title && (
                    <div
                      className={`text-primary-text font-medium w-full ${titleFont} ${
                        titleSize ? `text-${titleSize}` : ''
                      } text-2xl font-inter `}
                    >
                      {title}
                    </div>
                  )}
                  {onClose && (
                    <div
                      id="close-button"
                      data-testid="close-button"
                      onClick={onClose}
                      className="cursor-pointer"
                    >
                      {Icon ? (
                        <Icon />
                      ) : (
                        <CrossIcon className={'text-primary-text h-[25px] w-[25px]'} />
                      )}
                    </div>
                  )}
                </div>
              )}
              {showCancelButton && (
                <span onClick={onClose}>
                  <CrossIcon
                    className={`xs:hidden sm:block h-[25px] w-[25px] cursor-pointer absolute z-10 ${
                      isOnBoarding
                        ? 'relative fill-black !inline top-5 left-5'
                        : 'fill-grey-lighter z-10 top-10 left-10'
                    } `}
                  />
                </span>
              )}
              <div
                className={`modalBlock rounded-b-xl overflow-y-auto overflow-x-hidden ${
                  backgroundColor ? backgroundColor : 'bg-white'
                }`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={`${
                      width ? `${width}` : ' max-w-[799px]'
                    }  ${classes} transform overflow-hidden text-left transition-all`}
                  >
                    {title && showBorder && (
                      <div
                        className={`border-primary-outline border-b ${
                          isBorderWidthFull ? 'w-full' : 'mx-6'
                        }`}
                      />
                    )}
                    <div className={childClass}>{children}</div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
AppModalBox.defaultProps = {
  title: '',
  backgroundColor: 'bg-white',
  onClose: undefined,
  childClass: '',
  showCancelButton: false,
  margin: '',
  height: 'h-auto',
  isOnBoarding: false,
  padding: 'p-7',
  position: 'my-auto',
  bgColor: '',
  isBorderWidthFull: true,
  showBorder: true, // Default value for the new prop
}
