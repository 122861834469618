import tw from 'twin.macro'
import styled from 'styled-components'

export const AppCardWrapper = styled.div`
  ${tw`relative bg-white xs:col-span-1 sm:col-span-12 md:col-span-6 lg:col-span-4 overflow-hidden shadow-[0px_16px_32px_-16px_#00000014;] !rounded-2xl border border-background-hover min-w-[200px] flex flex-col items-center justify-between cursor-pointer`}
`

export const AppCardDetails = styled.div`
  ${tw` p-8 pb-4 flex flex-col gap-6 w-full cursor-pointer`}
`

export const AppCardFooter = styled.div`
  ${tw` px-6 py-4  h-[72px] w-full border-t border-t-background-hover bg-app-grey-35`}
`

export const AppTitle = styled.h2`
  ${tw`text-base font-semibold leading-5`}

  @apply text-lg  text-16 text-center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
`

export const AppProgressBarWrapper = styled.div`
  ${tw`h-auto w-full flex flex-col items-end`}
`

export const AppProgressContainer = styled.div`
  ${tw`flex flex-col justify-center h-5 w-full`}
`

export const AppBackgroundBar = styled.div`
  ${tw`w-full h-[6px] bg-app-white-95 rounded-[21px] overflow-hidden`}
`

export const AppProgressFill = styled.div<{ progress: number }>`
  ${tw`bg-primary-appBrand rounded-[21px_0_0_21px] h-[6px]`}
  width: ${({ progress }) => `${progress}%`};
`

export const AppProgressLabel = styled.div`
  ${tw`text-sm flex justify-center items-center text-black leading-5`}
`

export const AppDropdownMenu = styled.div`
  ${tw`absolute bg-background-light rounded-lg z-[1000] p-2 shadow-lg w-40`}
  top: 40px;
  right: 0;
`

export const AppDropdownItem = styled.button<{ disabled?: boolean }>`
  ${tw`w-full p-2 text-left font-medium text-sm`}
  background: none;
  border: none;
  color: ${({ disabled }) => (disabled ? '#BDBDBD' : '#333')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'none' : '#DEDEDE')};
  }
`
