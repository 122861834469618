import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  BusinessProfileContainer,
  ClientDetailsUserProfileContainer,
  ClientOtherDetailsContainer,
  EditClientContainer,
  FilesContainer,
} from 'pages/Clients/container'
import Link from 'components/Common/Link'

const ClientProfileDetails = ({
  userId,
  setActiveTab,
  openClientDetail,
  isFormDataChanged,
  setIsFormDataChanged,
  getClientProfileDetailsAction,
}) => {
  let { clientId } = useParams()
  const [showDetail, setShowDetail] = useState(false)
  useEffect(() => {
    getClientProfileDetailsAction({ clientId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (!openClientDetail) {
      setShowDetail(false)
    }
  }, [openClientDetail])

  return (
    <>
      {!showDetail && (
        <div>
          <div className="xs:p-4 xs:w-full sm:w-64 sm:p-5 sm:pr-0">
            <Link className={'xs:text-xl sm:text-xl'} onClick={() => setShowDetail(true)}>
              Edit Details
            </Link>
          </div>
          <div className="grid xs:grid-cols-12 xs:gap-x-8 lg:gap-x-6 xs:gap-y-12 sm:gap-y-12 sm:px-5">
            <ClientDetailsUserProfileContainer />
            <BusinessProfileContainer />
            <ClientOtherDetailsContainer />
            <FilesContainer userId={clientId} />
          </div>
        </div>
      )}
      {showDetail && (
        <EditClientContainer
          setShowDetail={setShowDetail}
          setActiveTab={setActiveTab}
          setIsFormDataChanged={setIsFormDataChanged}
          isFormDataChanged={isFormDataChanged}
        />
      )}
    </>
  )
}

export default ClientProfileDetails
