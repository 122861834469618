import {
  AccountIcon,
  CalenderIcon,
  ChatUpdatedVersionIcon,
  ClientsIcon,
  DashboardIcon,
  DataAndReportsIcon,
  MyBusinessIcon,
  NotificationCircleIcon,
  PlayBooksIcon,
  ToolsIcon,
  UserManagementIcon,
} from 'components/Common/SvgIcons'
import { ROLES } from 'config'

/** All destinations have an id that makes them uniquely identifiable as an entity */
const destinations = {
  account: {
    id: 'account',
    label: 'Account',
    icon: AccountIcon,
    xsWidth: '33.3%',
    smWidth: '20%',
    sm: true,
    xs: true,
    path: '/account',
    isHeader: true,
    isNav: false,
  },
  assessmentQuestion: {
    id: 'assessmentQuestion',
    label: 'Assessment Question',
    path: '/assessmentQuestion',
    isHeader: false,
    isNav: false,
  },
  review: {
    id: 'review',
    label: 'Review Assessment',
    path: '/assessment',
    isHeader: false,
    isNav: false,
  },
  // bell: {
  //   id: 'bell',
  //   label: 'Bell',
  //   icon: NotificationBellIcon,
  //   xsWidth: '33.3%',
  //   smWidth: '20%',
  //   sm: false,
  //   xs: false,
  //   isHeader: true,
  //   isNav: false,
  //   notification: {
  //     label: 'NotificationCircle',
  //     icon: NotificationCircleIcon,
  //     isnotification: true,
  //   },
  // },
  calendar: {
    id: 'calendar',
    label: 'Calendar',
    icon: CalenderIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/calendar',
    isHeader: false,
    isNav: true,
  },
  chat(options = {}) {
    const _options = {
      id: 'chat',
      label: 'Chat',
      icon: ChatUpdatedVersionIcon,
      path: `/chat`,
      xsWidth: 'w-5/12',
      smWidth: '20%',
      sm: false,
      xs: false,
      isHeader: true,
      isNav: false,

      notification: {
        label: 'NotificationCircle',
        icon: NotificationCircleIcon,
        isnotification: true,
      },
    }
    return { ..._options, ...options }
  },
  clients: {
    id: 'clients',
    label: 'Clients',
    icon: ClientsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/clients',
    route: 'clients',
    isHeader: false,
    isNav: true,
  },
  dashboard: {
    id: 'dashboard',
    label: 'Dashboard',
    icon: DashboardIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/dashboard',
    route: 'dashboard',
    isHeader: false,
    isNav: true,
  },
  data: {
    id: 'data',
    label: 'Data',
    icon: DataAndReportsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/data-charts/overview',
    isHeader: false,
    isNav: true,
  },
  dataAndReports: {
    id: 'dataAndReports',
    label: 'Data & Reports',
    icon: DataAndReportsIcon,
    path: '/data-reports/data',
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    isHeader: false,
    isNav: true,
  },
  createBusinessOwner: {
    id: 'createBusinessOwner',
    label: 'Create Business Owner',
    path: '/create-business-owner',
    isHeader: false,
    isNav: false,
  },
  managePlays: {
    id: 'managePlays',
    label: 'Manage Plays',
    icon: UserManagementIcon,
    xsWidth: 'w-4/12',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/manage-plays',
    route: 'manage-plays',
    isHeader: false,
    isNav: true,
  },
  myBusiness: {
    id: 'myBusiness',
    label: 'My Business',
    icon: MyBusinessIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/dashboard',
    route: 'dashboard',
    isHeader: false,
    isNav: true,
  },
  plays: {
    id: 'plays',
    label: 'Plays',
    path: `/plays`,
    isHeader: false,
    isNav: false,
  },
  attestation: {
    id: 'attestation',
    label: 'Attestation',
    path: `/attestation`,
    isHeader: false,
    isNav: false,
  },
  playBooks: {
    id: 'playBooks',
    label: 'Playbooks',
    icon: PlayBooksIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: `/playbooks`,
    isHeader: false,
    isNav: true,
  },
  playBooksCategory: {
    id: 'playBooksCategory',
    label: 'Playbooks Category',
    path: `/playbooks-category`,
    isHeader: false,
    isNav: false,
  },
  // tools: {
  //   id: 'tools',
  //   label: 'My Tools',
  //   icon: ToolsIcon,
  //   xsWidth: 'w-1/4',
  //   smWidth: 'w-1/5',
  //   sm: true,
  //   xs: false,
  //   path: '/tools/featured',
  //   isHeader: false,
  //   isNav: true,
  // },
  tools: {
    id: 'tools',
    label: 'Tools',
    icon: ToolsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/tools/featured',
    isHeader: false,
    isNav: true,
  },
  userManagement: {
    id: 'userManagement',
    label: 'User Management',
    icon: UserManagementIcon,
    xsWidth: 'w-4/12',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/user-management/businesses',
    route: 'user-management',
    isHeader: false,
    isNav: true,
  },
  dashboardV2: {
    id: 'dashboardV2',
    label: 'Dashboard',
    icon: ToolsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/dashboard',
    isHeader: false,
    isNav: true,
  },
  goals: {
    id: 'goals',
    label: 'Goals Center',
    icon: ToolsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/goals',
    isHeader: false,
    isNav: true,
  },
  kanban: {
    id: 'kanban',
    label: 'Kanban',
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/kanban',
    isHeader: false,
    isNav: false,
  },
  onboardingV2: {
    id: 'onboardingv2',
    label: 'Onboarding',
    icon: ToolsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/onboarding',
    isHeader: false,
    isNav: true,
  },
  appTools: {
    id: 'tools',
    label: 'Tools',
    icon: ToolsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/tools/featured',
    isHeader: false,
    isNav: true,
  },
  appData: {
    id: 'data',
    label: 'Data',
    icon: DataAndReportsIcon,
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/data-charts/overview',
    isHeader: false,
    isNav: true,
  },
  appHome: {
    id: 'data',
    label: 'Home',
    xsWidth: 'w-1/4',
    smWidth: 'w-1/5',
    sm: true,
    xs: false,
    path: '/home',
    isHeader: false,
    isNav: true,
  },
}

const addDestinations = (role: string, destinations) => {
  return destinations.map((d) => {
    const copy = { ...d }
    if ('path' in copy) {
      copy.path = `/${role}/:tenantId${copy.path}`
    }
    return copy
  })
}

/**
 * Note that paths are modified by addDestinations.
 * The order of the items matters.
 * */
const NAVIGATION = {
  [ROLES.APP_ADMIN]: addDestinations(ROLES.APP_ADMIN, [
    destinations.dashboard,
    destinations.clients,
    destinations.dataAndReports,
    destinations.calendar,
    destinations.userManagement,
    destinations.managePlays,
    destinations.chat(),
    destinations.account,
  ]),
  [ROLES.BSO_ADMIN]: addDestinations(ROLES.BSO_ADMIN, [
    destinations.dashboard,
    destinations.clients,
    destinations.dataAndReports,
    destinations.calendar,
    destinations.userManagement,
    destinations.chat(),
    destinations.account,
    destinations.createBusinessOwner,
  ]),
  [ROLES.BSO_ADVISOR]: addDestinations(ROLES.BSO_ADVISOR, [
    destinations.dashboard,
    destinations.clients,
    destinations.dataAndReports,
    destinations.tools,
    destinations.calendar,
    destinations.chat(),
    destinations.account,
    destinations.createBusinessOwner,
  ]),
  [ROLES.BUSINESS_OWNER]: addDestinations(ROLES.BUSINESS_OWNER, [
    destinations.myBusiness,
    destinations.playBooks,
    destinations.data,
    destinations.tools,
    destinations.calendar,
    destinations.chat({
      xsWidth: 'w-1/4',
      smWidth: 'w-1/5',
      sm: true,
      isHeader: false,
    }),
    destinations.chat({
      xsWidth: '33.3%',
      smWidth: '20%',
      isNav: false,
      notification: {
        label: 'NotificationCircle',
        icon: NotificationCircleIcon,
        isnotification: true,
      },
    }),
    destinations.account,
    destinations.assessmentQuestion,
    destinations.plays,
    destinations.attestation,
    destinations.playBooksCategory,
    destinations.review,
  ]),
  [ROLES.V2]: addDestinations(ROLES.V2, [
    // destinations.dashboardV2,
    destinations.appHome,
    destinations.goals,
    destinations.kanban,
    destinations.account,
    destinations.calendar,
    destinations.appTools,
    destinations.appData,
  ]),
  [ROLES.V2_ADVISOR]: addDestinations(ROLES.V2_ADVISOR, [
    destinations.createBusinessOwner,
    destinations.clients,
    destinations.calendar,
    destinations.appTools,
    destinations.appData,
    destinations.appHome,
  ]),
  [ROLES.V2_ADMIN]: addDestinations(ROLES.V2_ADMIN, [
    destinations.createBusinessOwner,
    destinations.clients,
    destinations.calendar,
    destinations.appTools,
    destinations.appData,
    destinations.appHome,
  ]),
}

export default NAVIGATION
