import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { LABEL, industry } from 'config'

import Field from 'components/Clients/Client/Details/Field'
import Loader from 'components/Loaders'
import { isEmpty } from 'lodash'
import { calculateYearMonths } from 'utils/helper'
import { registeredBusinessStructure } from 'components/MonthlyAttestation/utils'
import { BusinessProfile as BusinessProfileResponse } from '__generated__/api-types-and-hooks'
import { AppFC } from 'types'
interface IBusinessProfile {
  businessProfile: BusinessProfileResponse
  clientBusinessProfileLoading: boolean
  getUserBusinessProfileAction: (payload) => {}
}
const BusinessProfile: AppFC<IBusinessProfile> = ({
  businessProfile,
  clientBusinessProfileLoading,
  getUserBusinessProfileAction,
}) => {
  let { clientId } = useParams()

  useEffect(() => {
    if (clientId) {
      getUserBusinessProfileAction({ userId: clientId })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserBusinessProfileAction])
  return (
    <div className="xs:col-span-12 xs:p-4 sm:p-0 col-span-3 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-3  lg:border-r lg:border-grey-darker break-words">
      {clientBusinessProfileLoading ? (
        <Loader loader="AdvisorOwnerProfile" />
      ) : (
        <>
          <div className="font-primary  text-2xl">Business Profile </div>
          <div className="space-y-9 mt-[31px]">
            <Field label={LABEL.BUSINESS_NAME} value={businessProfile?.businessName} />
            <Field label={LABEL.BUSINESS_EMAIL} value={businessProfile?.businessEmail} />
            <Field label={LABEL.BUSINESS_WEBSITE} value={businessProfile?.domainName} />
            <Field
              label={LABEL.REGISTER_BUSINESS_STRUCTURE}
              value={
                businessProfile?.registerBusinessStructureOptionId !==
                registeredBusinessStructure.tellMeMoreOptionId
                  ? businessProfile?.registerBusinessStructure
                  : ''
              }
            />
            <div className=" grid grid-cols-12 gap-2 mt-3 ">
              <div className="col-span-6">
                <Field label={LABEL.MONTH} value={businessProfile?.month} />
              </div>
              <div className="col-span-6">
                <Field label={LABEL.YEAR} value={businessProfile?.year} />
              </div>
            </div>
            <Field
              label={LABEL.YEARS_IN_BUSINESS}
              value={calculateYearMonths(businessProfile?.month!, businessProfile?.year!)}
            />
            <Field label={LABEL.BUSINESS_STAGE} value={businessProfile?.businessStage} />

            <Field
              label={LABEL.BUSINESS_CLASSIFICATION}
              value={businessProfile?.businessClassification}
            />

            {!isEmpty(businessProfile?.businessClassificationOptionId) &&
              (businessProfile?.businessClassificationOptionId === industry.foodId ||
                businessProfile?.businessClassificationOptionId === industry.servicesId) && (
                <Field
                  label={`Type of ${businessProfile?.businessClassification} Industry`}
                  value={
                    businessProfile?.servicesSubIndustry ||
                    businessProfile?.foodSubIndustry?.join(', ')
                  }
                />
              )}
            {!isEmpty(businessProfile?.businessClassificationOptionId) &&
              businessProfile?.businessClassificationOptionId !== industry.foodId && (
                <Field
                  label="Business Location"
                  value={businessProfile?.businessLocations?.join(', ')}
                />
              )}
            <Field label={LABEL.FULL_TIME_EMPLOYEES} value={businessProfile?.fullTimeEmployees} />
            <Field label={LABEL.PART_TIME_EMPLOYEES} value={businessProfile?.partTimeEmployees} />
            <Field label={LABEL.CONTRACT_EMPLOYESS} value={businessProfile?.contractors} />
            <Field label={LABEL.VOLUNTEERS} value={businessProfile?.volunteers} />
          </div>
        </>
      )}
    </div>
  )
}

export default BusinessProfile
