import React, { useState, useEffect } from 'react'
import {
  BusinessGoalCategory,
  BusinessGoalPriority,
  BusinessGoalsFilterData,
  BusinessGoalTimeFrame,
  UserTaskItemStatus,
} from '__generated__/api-types-and-hooks'
import {
  FilterTooltips,
  goalPriorityStatus,
  goalTimeFrameStatus,
  UserTaskItemStatusChips,
  UserTaskItemStatusesStatus,
} from 'appConfig/data'
import { BusinessGoalTimeFrames } from '../AppGoalCenterConfig'
import { Divider } from '../AppGoalCenter.style'
import AppUpArrowIcon from 'components/Common/AppSvgIcons/AppUpArrowIcon'
import AppDownArrowIcon from 'components/Common/AppSvgIcons/AppDownArrowIcon'
import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppInfoIcon } from 'components/Common/AppSvgIcons/AppInfoIcon'

interface IFilterPopupProps {
  onClose: () => void
  isOpen: boolean
  selectedFilters: BusinessGoalsFilterData
  setSelectedFilters: React.Dispatch<React.SetStateAction<BusinessGoalsFilterData>>
  disabled?: boolean
}

const AppFilterPopup: React.FC<IFilterPopupProps> = ({
  onClose,
  isOpen,
  selectedFilters,
  setSelectedFilters,
  disabled,
}) => {
  const [tempFilters, setTempFilters] = useState<BusinessGoalsFilterData>({
    priorities: [],
    statuses: [],
    categories: [],
    timeFrames: [],
  })
  useEffect(() => {
    if (isOpen) {
      setTempFilters(selectedFilters)
    }
  }, [isOpen, selectedFilters])

  const categoryTypeMap: Record<
    keyof BusinessGoalsFilterData,
    | BusinessGoalPriority
    | UserTaskItemStatus
    | BusinessGoalCategory
    | BusinessGoalTimeFrame
    | Boolean
  > = {
    priorities: {} as BusinessGoalPriority,
    statuses: {} as UserTaskItemStatus,
    categories: {} as BusinessGoalCategory,
    timeFrames: {} as BusinessGoalTimeFrame,
    isArchive: false,
  }

  const toggleSelection = (category: keyof BusinessGoalsFilterData, option: string) => {
    setTempFilters((prevFilters) => {
      const typedOption = option as typeof categoryTypeMap[typeof category]
      const currentCategory = (prevFilters[category] as typeof typedOption[]) || []

      return {
        ...prevFilters,
        [category]: currentCategory.includes(typedOption)
          ? currentCategory.filter((item) => item !== typedOption)
          : [...currentCategory, typedOption],
      }
    })
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !document.getElementById('filter-menu')?.contains(event.target as Node)) {
        setSelectedFilters(tempFilters)
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, onClose, tempFilters, setSelectedFilters])

  return isOpen ? (
    <div className="absolute sm:top-[100%] mt-2 rounded-xl z-50 bg-background-light border border-background-hover p-5 w-full px-6 py-7 xs:max-w-[250px] xs:top-0 xs:right-[100%] sm:right-auto xs:w-[250px] sm:w-[193px] sm:max-w-[200px]  md:max-h-[500px] overflow-y-auto">
      <div id="filter-menu" className="flex flex-col gap-3">
        <p className="text-base font-inter font-medium text-black-appDark leading-6">Filters</p>

        <Divider />

        <AppFilterChips
          title="Priority"
          filterList={BusinessGoalPriority}
          displayFilterList={goalPriorityStatus}
          selectedFilters={tempFilters.priorities ?? []}
          onToggle={(filter) => toggleSelection('priorities', filter)}
        />

        <Divider />

        <AppFilterChips
          title="Time Frame"
          filterList={BusinessGoalTimeFrames}
          displayFilterList={goalTimeFrameStatus}
          selectedFilters={tempFilters.timeFrames ?? []}
          onToggle={(filter) => toggleSelection('timeFrames', filter)}
          showToolTip={true}
        />

        <Divider />

        <AppFilterChips
          title="Status"
          filterList={UserTaskItemStatusChips}
          displayFilterList={UserTaskItemStatusesStatus}
          selectedFilters={tempFilters.statuses ?? []}
          onToggle={(filter) => toggleSelection('statuses', filter)}
          disabled={disabled}
        />
      </div>
    </div>
  ) : null
}
interface IFilterChipsProps {
  title: string
  filterList: Record<string, string>
  displayFilterList: Record<string, string>
  selectedFilters: string[]
  onToggle: (option: string) => void
  showToolTip?: boolean
  disabled?: boolean
}

export const AppFilterChips: React.FC<IFilterChipsProps> = ({
  title,
  filterList,
  displayFilterList,
  selectedFilters,
  onToggle,
  showToolTip = false,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <div>
      <h3
        className=" flex font-inter justify-between items-center gap-1 text-sm text-normal leading-5 text-black-appDime cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        {isOpen ? <AppUpArrowIcon /> : <AppDownArrowIcon />}
      </h3>
      {isOpen && (
        <div className="flex flex-col px-3 gap-1">
          {Object.entries(filterList).map(([key, filter]) => (
            <div key={key} className="flex items-center gap-2">
              <AppCheckBox
                id={key}
                label={displayFilterList[filter]}
                value={selectedFilters.includes(filter)}
                onChange={() => {
                  if (!disabled) {
                    onToggle(filter)
                  }
                }}
                disabled={disabled}
              />
              {showToolTip && (
                <AppToolTip name={filter} text={FilterTooltips[filter]}>
                  <AppInfoIcon width="15px" height="15px" />
                </AppToolTip>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AppFilterPopup
