import React, { forwardRef, memo } from 'react'

import 'react-phone-input-2/lib/style.css'
import appColors from 'appColors'

import PhoneInput from 'react-phone-input-2'
import { AppPhoneInputWrapper, DividerWrapper } from './AppCountryPhoneInput.style'

import { AppAlertIcon } from 'components/Common/AppSvgIcons/AppAlertIcon'
import {
  AppErrorMessageWrapper,
  AppErrorWrapper,
  AppFieldParentWrapper,
  AppLabelWrapper,
} from '../AppCommonStyles/AppFormField.style'

interface ICountryPhoneInputProps {
  name: string
  error?: string
  disabled?: boolean
  placeholder?: string
  label?: string
  value?: string
  onChange?: (value) => void
  size: 'lg' | 'md' | 'sm'
  onBlur?: (value) => void
}

const MemoizeCountryPhoneInput = forwardRef<any, ICountryPhoneInputProps>(
  ({ error, disabled = false, placeholder = '', label, size, value, onChange, onBlur }) => {
    return (
      <div className="w-full">
        {label && <AppLabelWrapper>{label}</AppLabelWrapper>}
        <AppFieldParentWrapper>
          <AppPhoneInputWrapper size={size} error={!!error} disabled={!!disabled}>
            <PhoneInput
              country={'us'}
              disabled={disabled}
              buttonStyle={{
                backgroundColor: error ? 'app-red-10' : 'transparent',
                width: '45px',
                borderTop: 'none',
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                position: 'absolute',
                left: '0',
                top: '0',
                zIndex: '999',
              }}
              inputClass={disabled ? 'text-app-grey-50' : ''}
              placeholder={placeholder}
              onChange={(phoneValue: string) => {
                const formattedPhoneValue = phoneValue.startsWith('+')
                  ? phoneValue
                  : `+${phoneValue}`
                onChange && onChange(formattedPhoneValue)
              }}
              value={value}
              countryCodeEditable={false}
              onBlur={onBlur}
            />
            <DividerWrapper className="divider" error={!!error} />
          </AppPhoneInputWrapper>
          {error && <AppErrorMessage error={error} />}
        </AppFieldParentWrapper>
      </div>
    )
  }
)

export const AppCountryPhoneInput = memo(MemoizeCountryPhoneInput)

export const AppErrorMessage = ({ error }) => {
  return (
    <AppErrorWrapper>
      <AppAlertIcon color={appColors['app-red']['50']} />
      <AppErrorMessageWrapper>{error}</AppErrorMessageWrapper>
    </AppErrorWrapper>
  )
}
